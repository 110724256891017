<template>
  <div id="app-mobile" class="box">
    <div class="img"></div>
    <div class="title">{{ info.title }}</div>
    <div class="content">
      {{ info.remark }}
    </div>
    <div
      class="errorTitle"
      v-if="
        info.answerType === '' ||
        info.answerType === null ||
        info.answerType === undefined
      "
    >
      暂时不能答题
    </div>
    <div class="btn" v-else>
      <van-button
        class="btnLeft"
        @click="$router.push(`/mobile/questionContent/${info.id}/${0}`)"
        v-if="info.reply === null"
        >开始答题</van-button
      >
      <van-button
        class="btnLeft"
        @click="
          $router.push(`/mobile/questionContent/${info.id}/${info.reply.id}`)
        "
        v-if="info.reply !== null"
        >继续答题</van-button
      >
    </div>
  </div>
</template>

<script>
import { questionnaireCheckPermission } from "@/apis/mobileApi";
import { getToken } from "@/utils/cookie";
export default {
  name: "questionTitle",
  data() {
    return {
      info: {},
      uid: "",
    };
  },
  mounted() {
    if(getToken()){
      this.getCheckPermission();
    }else{
      // setTimeout(() => {
      //   this.$router.go(0);
      // }, 1000);
    }
  },
  created() {},

  methods: {
    getCheckPermission() {
      questionnaireCheckPermission({
        username: getToken(),
        uid: this.$route.params.uid,
      }).then(({ data }) => {
        this.info = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$room: 1020 / 375;
.box {
  // width: 100vw;
  height: calc(100vh - 50px * $room);
  background: #f4f5f8;
}
.img {
  width: 100vw;
  height: calc(222px * $room - 50px * $room);
}
.title {
  display: flex;
  justify-content: center;
  font-size: 32px * $room;
  font-weight: 540;
}
.content {
  padding: 50px * $room 11px * $room 130px * $room 20px * $room;
  font-size: 16px * $room;
  line-height: 28px * $room;
}
.btn {
  display: flex;
  justify-content: center;
  .btnLeft {
    font-size: 16px * $room;
    padding: 24px * $room 44px * $room;
    border-radius: 5px * $room;
    background: #3a8557;
    color: #fff;
  }
}
.errorTitle {
  font-size: 20px * $room;
  display: flex;
  justify-content: center;
}
</style>
